@import '../../../../scss/theme-bootstrap';

.gnav-links {
  @include breakpoint($landscape-up) {
    height: 100%;
  }
  &__trigger {
    &:checked {
      ~ .gnav-links__content {
        display: block;
      }
      ~ .gnav-links__header {
        .icon--minus {
          display: inline-block;
        }
        .icon.expandable {
          display: none;
        }
      }
    }
  }
  &__header {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__header-label {
    display: block;
    width: 100%;
    line-height: 1.2;
    padding-#{$ldirection}: 20px;
    font-size: 21px;
    font-family: $font--heading;
    color: $color-light-black;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      display: inline-block;
      padding-#{$ldirection}: 0;
      font-size: 28px;
      line-height: 1.1;
      margin-bottom: 17px;
      width: auto;
      letter-spacing: -0.01em;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:focus,
        &:hover {
          color: $color-neutral-gray;
        }
      }
    }
  }
  &__header-link {
    font-size: 21px;
    font-family: $font--heading;
    color: $color-light-black;
    line-height: get-line-height(29px, 26px);
    @include breakpoint($landscape-up) {
      font-size: 28px;
      line-height: get-line-height(31px, 28px);
    }
    &:focus,
    &:hover {
      color: $color-light-black;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:focus,
        &:hover {
          color: $color-neutral-gray;
        }
      }
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__content {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
    .gnav-links__header ~ & {
      padding-#{$ldirection}: #{$gnav-mobile-hpad--outer};
      padding-bottom: 25px;
      @include breakpoint($landscape-up) {
        background-color: transparent;
        padding-#{$ldirection}: 0;
        height: calc(100% - #{$gnav-mobile-link-height});
      }
    }
  }
  &__content-links {
    .gnav-links--columns-break & {
      @include breakpoint($landscape-up) {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      @for $i from 1 through 10 {
        :not(.gnav-links__link--break).gnav-links__link:nth-child(#{$i}) {
          @include breakpoint($landscape-up) {
            order: $i;
          }
        }
        &--break-afer-#{$i} .gnav-links__link--break {
          @include breakpoint($landscape-up) {
            flex-basis: 100%;
            width: 0;
            overflow: hidden;
            order: $i;
          }
        }
      }
    }
  }
  &__link {
    line-height: 2.5;
    @include breakpoint($landscape-up) {
      line-height: 1.9;
      margin-bottom: 8px;
    }
    &--viewall {
      @include breakpoint($landscape-up) {
        position: absolute;
        bottom: 0;
      }
    }
    .gnav-links--columns-break & {
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
  }
  &__mobile-icon {
    display: inline-block;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    @if $cr22 {
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        fill: $color-white;
      }
    }
    &.icon--minus {
      display: none;
    }
  }
  .gnav-link {
    font-size: 14px;
    line-height: get-line-height(21px, 14px);
    letter-spacing: 0.01em;
    font-family: $font--text;
    color: $color-darker-gray;
    @include breakpoint($landscape-up) {
      font-size: 15px;
      line-height: get-line-height(31px, 28px);
      &:focus,
      &:hover {
        color: $color-dark-gray;
      }
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:focus,
        &:hover {
          color: $color-neutral-gray;
        }
      }
    }
  }
}
