@import 'z-index';

///
/// @file global/_functions.scss
///
/// \brief Functions
///
/// Useful functions to help us calculate various values
/// (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
///

// http://stackoverflow.com/a/11752227
@function compact(
  $var-1,
  $var-2: false,
  $var-3: false,
  $var-4: false,
  $var-5: false,
  $var-6: false,
  $var-7: false,
  $var-8: false,
  $var-9: false,
  $var-10: false
) {
  $full: $var-1;
  $vars: $var-2, $var-3, $var-4, $var-5, $var-6, $var-7, $var-8, $var-9, $var-10;

  @each $var in $vars {
    @if $var {
      $full: $full, $var;
    }
  }
  @return $full;
}

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return string;
}

// loop through nested maps
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

// check maps for a particular key value
@function deep-map-check($map, $keys...) {
  @if type-of($map) != 'map' {
    @error 'The argument $map: `#{$map}` is the wrong type: `#{type-of($map)}`. Type of `Map` is required';
  }
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

// splits a string by a given delimiter
@function str-explode($string, $delimiter: '') {
  @if type-of($string) != 'string' {
    @error 'The argument $string: `#{$string}` is the wrong type: `#{type-of($string)}`. Type of `string` is required';
  } @else if type-of($delimiter) != 'string' {
    @error 'The argument $delimiter: `#{$delimiter}` is the wrong type: `#{type-of($delimiter)}`. Type of `delimiter` is required';
  }

  $result: ();
  $running: true;

  @if str-length($delimiter) == 0 {
    @for $i from 1 through str-length($string) {
      $result: append($result, str-slice($string, $i, $i));
    }
    @return $result;
  }

  @while $running {
    $index: str-index($string, $delimiter);
    @if $index != null {
      $item: str-slice($string, 1, ($index - 1));
      $result: append($result, $item);
      $string: str-slice($string, ($index + str-length($delimiter)));
    } @else {
      $running: false;
    }
  }
  @return append($result, $string);
}

// enables the get keyword to pull nested content from sass maps, allowing you to use dot "." notation
@function get($key, $map) {
  $keys: str-explode($key, '.');

  @if not deep-map-check($map, $keys...) {
    @error 'The argument $map: `#{$map}` is missing some of the $keys: `#{$keys}`. Type of `Map` is required';
  }
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
